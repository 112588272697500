<template>
    <div>
        <p v-if="hintText">{{hintText}}</p>
        <v-textarea
            filled
            name="string"
            :label="placeholder"
            auto-grow
            single-line
            :placeholder="placeholder"
            v-model="answer"
            :rules="isRequired ? rules : []"
            autocomplete="off"
            @blur="updateAnswer"
        ></v-textarea>
    </div>
</template>

<script>
import FieldTypes from '../../../mixins/FieldTypes';

export default {
    name: 'String',
    props: {
        placeholderText: {
            default: false
        },
        hintText: {
            default: null
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            answer: '',
            rules: [
                v => !!v || this.$t('fields.general.rules.required')
            ],
            placeholder: this.placeholderText || this.$t('fields.string.placeholder')
        };
    },
    mixins: [FieldTypes]
};
</script>
